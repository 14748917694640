@import 'src/assets/scss/theme/mixins';
@import 'src/assets/scss/variables';

form.submitted input.ng-invalid,
input.ng-dirty.ng-invalid {
  border: 1px solid red;
}

.login-input-group .password-eye {
  top: 30px !important;
}

.auth-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  align-items: center;
  background-image: url('../images/truck_image.png');
  background-size: cover;

  .btn {
    height: 50px;
  }

  .forgot {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: right;
    float: right;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.12px;
    line-height: 19px;
  }

  .company-logo {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    img {
      width: 180px;
    }
  }

  .layer {
    background-color: rgba(11, 11, 105, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .card {
    border-radius: 25px;
    box-shadow: 0 0 7px 0px lighten($shadow-color, 10%);
    border: none;
    width: 514px;

    .footer {
      padding: 0 20px;
      font-size: 14px;
      margin: 20px 0 0;
    }

    .card-title {
      @include auth-header-label;
    }

    .card-text {
      @include auth-header-small;
    }
  }
}

label {
  color: $black-color;
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 21px;
}

.action-wrapper {
  position: absolute;
  bottom: 20px;
}

a.active {
  display: block;
  min-height: max-content;
  height: 100%;
  color: $activated-color;
  border-bottom: 3px solid $activated-color !important;

  span {
    color: $activated-color !important;
  }
}

fa-icon .fa-sort {
  font-size: 13px;
  color: $black-color;
}

fa-icon .fa-sort-up,
fa-icon .fa-sort-down {
  font-size: 13px;
  color: $active-color;
}

fa-icon .fa-sort-down {
  margin-bottom: 3px;
}

fa-icon .fa-sort-up {
  margin-bottom: -3px;
}

.left {
  position: relative;

  img {
    position: absolute;
    left: 10%;
    top: 24%;
  }

  span {
    margin-left: 10px;
  }

  .fix-height {
    height: fit-content;
    min-height: 90vh;
  }
}

.p-dropdown {
  width: 100%;
}

.required {
  &:after {
    content: ' *';
    color: $color-danger;
  }
}

.sidebar-action-buttons {
  position: sticky;
  bottom: 5px;
  right: 15px;
  margin-top: 15px !important;
  background-color: white;
  z-index: 9999;
}

.p-dropdown-panel {
  z-index: 2000 !important;
  box-shadow: $dropdown-box-shadow;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel .p-dropdown-header {
  padding: 0.45rem 1.05rem;
  font-size: 15px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  height: 40px;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: $border-color;
}

p-inputnumber {
  display: contents;

  .p-inputnumber {
    display: contents;

    .p-inputtext {
      color: #333;
      border-radius: 3px;
      border: none;
      background: white;
      padding: 0.75rem 0.75rem;
      font-size: 16px !important;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-color: #ced4da;
      height: -webkit-fill-available;
      padding-left: 0;
    }
  }
}

.search-string {
  background-color: yellow;
  font-weight: bold;
}

.read-only {
  pointer-events: none !important;
  cursor: text !important;
}

.f-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px;
}

label {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  .input-group-prepend {
    .input-group-text {
      margin-left: -12px !important;

      .date-picker {
        width: 210px !important;
      }
    }
  }
}
