@import 'src/assets/scss/variables';

@mixin auth-header-label {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 42px;
  color: $black-color;
}

@mixin auth-header-small {
  color: $placeholder-color;
  font-size: 14.17px;
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 21px;
}
