@import 'mixins';

a:hover {
  cursor: pointer;
}

body {
  height: 100vh;
}

.container {
  height: 100%;

  &.logged-in {
    height: calc(100vh - #{$header-height});
  }
}

.btn-loader-icon {
  position: absolute;
  margin-left: 10px;
}

.container-fluid {
  padding-right: var(--bs-gutter-x, 1.75rem);
  padding-left: var(--bs-gutter-x, 1.75rem);
}
