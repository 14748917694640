.tabs {
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: $active-color !important;
    font-weight: 500 !important;
    border: none !important;
    background-color: transparent !important;
    border-bottom: 3px solid $active-color !important;
  }

  .nav-link {
    padding: 10px 25px;
    border-radius: 0 !important;
    color: darken($placeholder-color, 10%);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 25px;
    transition: none;
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent;
    isolation: isolate;
    font-weight: 500;
    color: darken($placeholder-color, 20%);
  }

  .nav-tabs {
    padding: 0 20px;
  }

  .tab-content {
    padding: 13px 20px;
  }
}

@media (max-width: 767px) {
  .tabs .nav-item {
    margin: 0;
  }
}
