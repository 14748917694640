@import '../variables';
thead {
  tr {
    background-color: $table-header-color;
    & > * {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}

tbody {
  // tr:nth-child(even)>* {
  //   background-color: darken($table-row-color, 1%);
  //   --bs-table-accent-bg: darken($table-row-color, 1%) !important;
  // }
  // tr:nth-child(odd)>* {
  //     background-color: white;
  //     --bs-table-accent-bg: white !important;
  // }
  tr {
    border-bottom: 1px solid darken($table-row-color, 3%);
    & > * {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.5rem !important;
}

.table {
  margin-top: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 2px solid darken($table-row-color, 3%);
  border-left: none;
  border-right: none;
  padding: 0.8rem 1rem 0.6rem 1rem;
}

.p-datatable .p-datatable-header {
  border-top: none;
  padding: 0 0 1rem;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: $table-header-active-col-color;
  color: $active-color;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $active-color;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: $black-color;
  font-size: 12px;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  color: $black-color;
}

p-table {
  .p-multiselect .p-multiselect-panel {
    min-width: 300px;
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.55rem 0.75rem;
    font-size: 14px;
    color: $black-color;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }
  .p-multiselect-filter {
    padding: 0.3rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0.6rem 1.25rem;
  }
  .p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
    padding: 0.4rem 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: $active-color;
    font-size: 14px;
  }
  .p-multiselect-panel .p-multiselect-header {
    padding-right: 10px;
  }
}
