$main-padding: 10px;
$header-height: 61px;
$header-bg: #f1f1f1;
$shadow-color: #aaaaaa24;
$box-shadow: 0 10px 20px 0 $shadow-color;
$dropdown-box-shadow: 0 2px 10px 0 #00000040;
$card-box-shadow: 2px 2px 24px 0 #44444429;
$badge-box-shadow: 2px 2px 4px #00000033;
$badge-bg-color: #0000ff08;
$theme-light-color: #fff8f5;
$primary-color: #101012;
$black-color: #222222;
$placeholder-color: #989898;
$success-color: #598d51;
$active-color: #455fd7;
$table-header-color: #f3f5f8;
$table-row-color: #fafbfe;
$gray-text: #949494;
$table-header-active-col-color: #e6f1ff;
$activated-color: #007aff;
$card-icon-text: #b1b2b3;
$color-success: green;
$color-danger: red;
$color-warning: yellow;
$card-common-color: white;
$background-color: black;
$header-text-color: #e7e7e7;
$icon-color: #9f9f9f;
$navigation-icon-muted-color: #b3b3b4;
$header-line-color: #434343;
$table-form-input-color: #d5d5d5;
$table-total-hours: #ebf4ff;
$tag-color: #1e3353;
$tag-backround: #415370;
$table-border-color: #ebebeb;
$table-filed-highlighted: #acacac;
$primary-font-color: #1e3353;
$footer-bg: #e0e0e0;
$form-list-text: #8f9092;
$form-border: #eaeaea;
$time-material: #aabfde;
$perdiem-sidebar-box: #ecf5ff;
$is-tm: #fff1f1;
$form-placeholder-color: #6c757d;
$border-color: #ced4da;
$perdiem-sidebar-box: #ecf5ff;
$yellow-highlight-color: #fefdea;
$progress-bar-color: #169d18;
$quantities-table-th: #f5faff;
$delta-danger: #f85d5d;
$report-disable-text: #8e8f90;
$light-thin-border: #eeeeee;
$report-header: #eae6e6;
$job-box: #dadada;
$selected-job-color: #e0e6ea;
$multi-select: #f5f0f0;
$blue-text: #651fff;
$light-green: #fcfcfc;
$task-warning: #ed963a;
$dark-blue: #000c66;
$background-text: #f8f9fa;
$hover-blue-btn: #0b5ed7;
$hover-blue-border-btn: #0a58ca;
$light-blue-icon: #3962a1;
$active-blue-button: #9dc1fb;
$save-icon-bg-color: #dbe1e2;
$save-icon-border: #f9fafb;
$block-hover-color: #972a2a08;
$light-green: #c7f6b936;
$light-sky-color: #f7fafc;
$gray-border-color: #493e22;
$weather-cancel-card: #c8c8c8;
$table-font-hover-color: #343a40;
$table-font-hover-bg: #e9ecef;
