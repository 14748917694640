@import 'src/assets/scss/variables';

.form-control,
textarea {
  border-radius: 9px;
  // background-color: $theme-light-color  !important;
  border-color: $border-color !important;

  &:focus {
    // background-color: $theme-light-color  !important;
    // border-color: darken($theme-light-color, 10%) !important;
    outline: 0;
    box-shadow: 0 0 0 0 $active-color !important;
  }
}

textarea {
  padding: 0.375rem 0.75rem;
  width: 100%;
}

.form-control {
  height: 48px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1px $theme-light-color !important;
}

.form-group {
  margin-bottom: 25px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $active-color;
  background: $active-color;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: $active-color;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: $form-placeholder-color;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}

.p-inputtext {
  font-family: Montserrat, sans-serif;
}
