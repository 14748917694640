/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import 'node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import 'node_modules/primeng/resources/primeng.min.css';
@import 'node_modules/primeicons/primeicons.css';
// @import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ngx-toastr/toastr';

/* Importing Datepicker SCSS file. */

@import 'assets/scss/variables';
@import 'assets/scss/theme/theme';
@import 'assets/scss/theme/mixins';
@import 'assets/scss/theme/button';
@import 'assets/scss/theme/form';
@import 'assets/scss/theme/card';
@import 'assets/scss/theme/font';
@import 'assets/scss/theme/tabs';
@import 'assets/scss/theme/table';
@import 'assets/scss/custom';

/* Importing Bootstrap SCSS file. */

@import 'node_modules/bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */

@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

.fix-height-timecard {
  height: fit-content;
  min-height: calc(100vh - 128px);
}

.body {
  scroll-behavior: smooth;
}
